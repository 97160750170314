<template>
    <div class="paging">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"  :current-page="currentPage ? currentPage : 1"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: ['total', 'pageSize','currentPage'],
    data() {
        return {
        }
    },
    methods: {
        handleCurrentChange(val) {
            this.$emit('handleCurrentChange', val)
        },
    }
}
</script>

<style lang="less" scoped>
.paging {
    width: 1200px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}

// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>