<template>
    <div class="homeOrder">
        <div class="title">我的关注</div>

        <div class="co" v-loading="loading">
            <div class="gl" @click="managementShow = !managementShow">管理</div>

            <!--  :style="{ border: !isSelected ? '1px solid #FF4242 !important' : '' }" -->
            <div class="son" @click.stop="storeClick(item)" v-for="(item, index) in AttentionStoreList" :key="index"
                :class="[managementShow ? 'managementClass' : '']">
                <div class="head">
                    <div class="img">
                        <img :src="item.storeLog" alt="">
                    </div>
                    <div class="info">
                        <div class="name">{{ item.storeName }} <span>诚{{ item.storeGrade }}</span> </div>
                        <div class="text">主营：{{ item.storeMajor }}</div>
                    </div>
                </div>
                <div class="list">
                    <div class="img" @click.stop="commClick(i)" v-for="(i, index) in item.mallSpuList.slice(0, 7)" :key="index">
                        <img :src="i.mainImage" alt="">
                    </div>
                </div>
                <div v-if="!item.mallSpuList"
                    style="width: 100%;text-align: center;margin: 0px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                    <img src="@/assets/wsj.png" style="width: 100px;object-fit: contain;" alt="">
                    <div style="width: 100%;margin-top: 10px;">暂无推荐</div>
                </div>
                <div class="img2" v-if="managementShow" @click="isSelectedClick.stop(item, index)">
                    <img v-if="!item.select" src="@/assets/icon/wxz.png" alt="">
                    <img v-else src="@/assets/icon/xuanz.png" alt="">
                </div>
            </div>
            <div v-if="AttentionStoreList.length == 0"
                style="width: 100%;text-align: center;margin: 50px auto;color: #666;display: flex;justify-content: center;flex-wrap: wrap;">
                <img src="@/assets/wsj.png" style="width: 250px;object-fit: contain;" alt="">
                <div style="width: 100%;margin-top: 10px;">暂无数据</div>
            </div>

            <div style="width: 100%;display: flex;justify-content: space-between;" v-if="managementShow">
                <div class="mrdz" @click="isSelectedClickAll">
                    <div class="img">
                        <img v-if="isSelected" src="@/assets/icon/wxz.png" alt="">
                        <img v-else src="@/assets/icon/xuanz.png" alt="">
                    </div>
                    <div class="text" v-html="isSelected ? '全选' : '全不选'"></div>
                </div>
                <div class="tn" @click="delCollect">取消关注</div>
            </div>

            <!-- 分页 -->
            <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.size"></paging>

        </div>
    </div>
</template>

<script>
import { getAttentionStore } from "@/utils/api/myApi/index"
import { getStoreFollowSubscription } from "@/utils/api/homeApi/index"
import paging from "@/components/paging.vue";
export default {
    name: 'myFocusOn',
    components: {
        paging
    },
    data() {
        return {
            managementShow: false,
            isSelected: true,
            loading: true,
            paging: {
                page: 1,
                size: 5,
                total: 0
            },
            // 关注列表
            AttentionStoreList: []
        }
    },
    mounted() {
        this.getAttentionStoreList()
    },
    methods: {
        // 跳转店铺
        storeClick(i) {
            if (this.managementShow) {
                this.isSelectedClick(i)
            } else {
                window.open(`#/enterpriseHome/enterpriseContent?ids=${i.storeId}`, '_blank');
            }
        },
        // 点击跳转商品
        commClick(i) {
            if (this.managementShow) {
                this.isSelectedClick(i)
            } else {
                window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
            }
        },
        async getAttentionStoreList() {
            const res = await getAttentionStore({
                currentPage: this.paging.page,
                pageSize: this.paging.size,
            })
            if (res.statusCode == 8201) {
                this.AttentionStoreList = res.data.records
                this.paging.total = res.data.total
                this.loading = false
                this.AttentionStoreList.map(d => {
                    this.$set(d, 'select', false)
                })
            }
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getAttentionStoreList()
        },
        // 点击管理后选择事件
        isSelectedClick(i, index) {
            this.AttentionStoreList.map(d => {
                if (i.storeId == d.storeId) {
                    d.select = !d.select
                }
            })
        },
        // 取消收藏
        delCollect() {
            this.AttentionStoreList.map(async d => {
                if (d.select) {
                    const res = await getStoreFollowSubscription({
                        ids: d.storeId,
                        type: 'cancel'
                    })
                    if (res.data) {
                        this.getAttentionStoreList()
                        this.$message({
                            message: "已取消收藏",
                            type: 'success',
                        })
                        this.managementShow = false
                    }
                }
            })
        },
        // 全选 - 全不选
        isSelectedClickAll() {
            const allTrue = this.AttentionStoreList.every(item => item.select === true);
            if (allTrue) {
                this.AttentionStoreList.map(d => d.select = false)
                this.isSelected = true
            } else {
                this.AttentionStoreList.map(d => d.select = true)
                this.isSelected = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.co {
        width: 100%;
        min-height: 670px;
        background-color: #fff;
        padding: 20px 38px;
        box-sizing: border-box;
        margin-top: 20px;

        >.gl {
            width: 100%;
            text-align: right;
            color: #333;
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 10px;
            user-select: none;
        }

        >.son:hover {
            border: 1px solid #FF4242;
            transition: all .3s;
        }

        >.son {
            width: 100%;
            height: 242px;
            border-radius: 4px;
            background-color: #fff;
            padding: 15px;
            box-sizing: border-box;
            margin-bottom: 10px;
            border: 1px solid #fff;
            transition: all .3s;
            cursor: pointer;
            position: relative;

            >.head {
                width: 100%;
                height: 70px;
                display: flex;
                align-items: center;

                >.img {
                    width: 67px;
                    height: 67px;
                    border-radius: 50%;
                    overflow: hidden;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                    }
                }

                >.info {
                    width: 800px;
                    height: 50px;
                    margin-left: 22px;

                    >.name {
                        font-size: 20px;
                        font-weight: bold;
                        color: #333;

                        >span {
                            display: inline-block;
                            width: 28px;
                            height: 16px;
                            background-color: #FF4242;
                            color: #fff;
                            margin-left: 5px;
                            text-align: center;
                            line-height: 16px;
                            font-size: 12px;
                            border-radius: 25px;
                            font-weight: 300;
                        }
                    }

                    >.text {
                        font-size: 12px;
                        color: #666;
                        width: 100%;
                        white-space: nowrap;
                        margin-top: 8px;
                        /* 禁止换行 */
                        overflow: hidden;
                        /* 超出部分隐藏 */
                        text-overflow: ellipsis;
                        /* 超出部分显示省略号 */
                    }
                }
            }

            >.list {
                width: 100%;
                height: auto;
                display: flex;
                margin-top: 10px;

                >.img {
                    width: 120px;
                    height: 120px;
                    border-radius: 6px;
                    overflow: hidden;
                    border: 1px solid #fff;
                    transition: all .3s;
                    margin-right: 15px;

                    >img {
                        width: 100%;
                        height: auto;
                    }
                }

                >.img:hover {
                    border: 1px solid #FF4242;
                    transition: all .3s;
                }
            }


            >.img2 {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 8px;
                right: 10px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }
    }
}

.mrdz {
    width: 100px;
    display: flex;
    font-size: 12px;
    color: #666;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;

    >.text {
        margin-left: 5px;
    }
}

.tn {
    width: 90px;
    height: 30px;
    background-color: #FF4242;
    border-radius: 1px 1px 1px 1px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.paging {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.managementClass {
    border: 1px solid #E0E0E0 !important;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>